<template>
  <div class="w-100">
    <div
      id="navbar-nav-shadow"
      :class="[{[$style[`active`]]: showNav}, $style[`navbar-nav-shadow`]]"
      @click="setShowNav"
    />
    <ul
      id="navbar-nav"
      class="mx-auto navbar-nav"
      :class="[{[$style[`active`]]: showNav}, {[$style[`rendered`]]: categoryList.length!==0}, $style[`navbar-nav`]]"
    >
      <li class="d-flex d-md-none nav-item mx-4">
        <div
          class="position-relative w-100"
        >
          <input
            v-model="queryString"
            type="text"
            class="d-inline-block form-control rounded-pill w-100 my-4"
            :class="$style[`search-input`]"
            placeholder="找商品"
          >
          <router-link
            :to="queryString!=='' ? `/product/list?queryString=${queryString}` : ''"
            class="d-flex border-0 align-items-center ms-1 me-lg-3 me-2 position-absolute text-dark"
            :class="$style[`search-icon`]"
          >
            <Icon
              name="search"
              :class="$style[`icon`]"
            />
          </router-link>
        </div>
      </li>
      <li class="nav-item mx-4 mb-0 font-weight-bold">
        <router-link
          class="nav-link"
          :class="$style[`nav-link`]"
          :to="'/product/list?catMainID=&catSubID='"
        >
          所有商品
        </router-link>
      </li>
      <li
        v-for="(category, index) in categoryList"
        :key="index"
        class="nav-item mx-4 mb-0 font-weight-bold dropdown"
        :class="[$style[`dropdown`], {[$style[`active`]]: selectedNavItem===category.catMainID}]"
        @click="category.subCategoryList.length>1 ? setSelectedNavItem(category.catMainID) : null"
      >
        <a
          :id="'dropdownMenuButton'+index"
          class="nav-link dropdown-toggle"
          :class="$style[`nav-link`]"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          @click="push('/product/list?catMainID='+category.catMainID)"
        >{{ category.catMain }}</a>
        <div
          v-if="category.subCategoryList.length>1"
          class="dropdown-menu"
          :class="$style[`dropdown-menu`]"
          aria-labelledby="'dropdownMenuButton'+index"
        >
          <router-link
            v-for="sub in category.subCategoryList"
            :key="sub.catSubID"
            class="dropdown-item"
            :class="[$style[`dropdown-item`], {'d-none': sub.catSubID==='none'}]"
            :to="'/product/list?catMainID='+sub.catMainID+'&catSubID='+sub.catSubID"
          >
            {{ sub.catSub }}
          </router-link>
        </div>
      </li>

      <li class="nav-item mx-4 mb-0 font-weight-bold">
        <a
          role="button"
          class="nav-link text-decoration-none"
          :class="$style[`nav-link`]"
          @click="setShowContactModal"
        >聯絡我們</a>
      </li>

      <!-- divider -->
      <div class="dropdown-divider border mx-4 d-block d-md-none" />

      <li
        v-if="memberID"
        class="d-flex d-md-none nav-item mx-4 mb-0 font-weight-bold"
      >
        <router-link
          class="nav-link"
          :class="$style[`nav-link`]"
          to="/member/detail"
        >
          會員中心
        </router-link>
      </li>
      <li
        v-if="memberID===null"
        class="d-flex d-md-none nav-item mx-4 mb-0 font-weight-bold"
      >
        <router-link
          class="nav-link"
          :class="$style[`nav-link`]"
          to="/login"
        >
          會員登入
        </router-link>
      </li>
      <li
        v-if="memberID===null"
        class="d-flex d-md-none nav-item mx-4 mb-0 font-weight-bold"
      >
        <a
          class="nav-link"
          :class="$style[`nav-link`]"
          href="/login"
        >新用戶註冊</a>
      </li>
      <li
        v-if="memberID!==null"
        class="d-flex d-md-none nav-item mx-4 mb-0 font-weight-bold"
      >
        <a
          role="button"
          class="nav-link text-decoration-none"
          :class="$style[`nav-link`]"
          @click="logoutMember"
        >登出帳號</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { computed, onMounted, ref, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const { getters, commit } = useStore()
    const { push } = useRouter()

    const memberID = computed(() => getters['member/getMemberID'])
    const categoryList = computed(() => getters['product/getCategoryList'])

    const setAlert = inject('setAlert')
    const showNav = inject('showNav')
    const setShowNav = inject('setShowNav')
    const setShowContactModal = inject('setShowContactModal')

    const queryString = ref('')
    const showSearchForm = ref(false)
    const selectedNavItem = ref('')

    const setShowSearchForm = (show) => {
      if (typeof show !== 'object') {
        showSearchForm.value = show
      } else {
        showSearchForm.value = !showSearchForm.value
      }
    }

    const setSelectedNavItem = (id) => {
      if (selectedNavItem.value === id) {
        selectedNavItem.value = ''
      } else {
        selectedNavItem.value = id
      }
    }

    const logoutMember = () => {
      commit('member/setMemberID', { data: null })
      commit('cart/setCart', { data: [] })
      commit('cart/setCartMount', { data: 0 })
      // localStorage.removeItem('memberID')
      setAlert(true, true, '您已成功登出')
      push('/')
    }

    onMounted(() => {
      // var prevScrollpos = window.pageYOffset
      // window.addEventListener('scroll', () => {
      //   console.log(window.pageYOffset)
      // })
      // window.onscroll = function () {
      //   var currentScrollPos = window.pageYOffset
      //   if (prevScrollpos > currentScrollPos) {
      //     document.getElementByID('navbar-ul').style.top = '0'
      //   } else {
      //     document.getElementByID('navbar-ul').style.top = '-50px'
      //   }
      //   prevScrollpos = currentScrollPos
      // }
    })

    return {
      logoutMember,
      queryString,
      memberID,
      showNav,
      setShowNav,
      setShowContactModal,
      showSearchForm,
      setShowSearchForm,
      categoryList,
      selectedNavItem,
      setSelectedNavItem,
      push
    }
  }
}
</script>
<style lang="scss" module>
$navbar-height: 40px;

.icon {
  fill: currentColor;
  height: 0.9rem;
  width: 0.9rem;
}

.search-input {
  background-color: rgba($primary, 0.1);
  border: 0;
}

.search-icon {
  background-color: transparent;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

/* navbar for mobile */
@media screen and (max-width: 768px) {
  .navbar-nav {
    background-color: $white;
    display: flex;
    height: 100vh;
    justify-content: flex-start;
    list-style: none;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform 0.3s;
    width: 80%;
    z-index: 1200;
  }

  .navbar-nav.active {
    transform: translateX(0%);
  }

  .navbar-nav-shadow {
    background-color: $dark;
    content: '';
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: -100vh;
    transition: opacity 0.3s;
    width: 100vw;
    z-index: 1199;
  }

  .navbar-nav-shadow.active {
    opacity: 0.5;
    top: 0;
  }

  .navbar-nav .nav-link {
    align-items: center;
    color: $dark;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  .navbar-nav .nav-link::after {
    border-color: $dark;
  }

  .dropdown {
    &::before {
      border-bottom: 0.13rem solid $dark;
      border-left: 0.13rem solid $dark;
      border-right: 0 solid transparent;
      border-top: 0 solid transparent;
      content: '';
      display: block;
      height: 0.35rem;
      position: absolute;
      right: 0;
      transform: rotate(-45deg) translate(-26px, -5px);
      transition: border 0.3s;
      width: 0.35rem;
    }

    &.active::before {
      border-bottom: 0 solid transparent;
      border-left: 0 solid transparent;
      border-right: 0.13rem solid $dark;
      border-top: 0.13rem solid $dark;
    }

    .dropdown-menu {
      display: flex;
      flex-direction: column;
      max-height: 0;
      overflow: hidden;
      padding-left: 1rem;
      text-align: left;
      transition: max-height 0.3s;
    }

    &.active .dropdown-menu {
      max-height: 100vh;
    }

    .dropdown-item {
      color: $dark;
      padding: 0.5rem  1rem;
      text-decoration: none;
    }
  }
}

@media screen and (min-width: 768px) {
  .navbar-nav {
    flex-wrap: wrap;
    justify-content: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s;
  }

  .navbar-nav.rendered {
    background-color: $primary;
    max-height: 100vh;
    overflow: visible;
  }

  .navbar-nav .nav-link {
    color: $white;
  }

  .dropdown {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 0 1rem;
    position: relative;
  }

  .dropdown::before {
    border-bottom: 0.13rem solid $white;
    border-left: 0.13rem solid $white;
    border-right: 0 solid transparent;
    border-top: 0 solid transparent;
    content: '';
    display: block;
    height: 0.35rem;
    position: absolute;
    right: 0;
    transform: rotate(-45deg) translate(-4px, -7px);
    width: 0.35rem;
  }

  .dropdown .dropdown-menu {
    background-color: $primary;
    display: flex;
    flex-direction: column;
    max-height: 0;
    min-width: 8rem;
    overflow: hidden;
    text-align: left;
    top: $navbar-height;
    transition: max-height 0.3s;
  }

  .dropdown:hover .dropdown-menu {
    max-height: 100vh;
  }

  .dropdown-item {
    background-color: $white;
    color: $dark;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: color 0.3s, background-color 0.3s;
  }

  .dropdown-item:hover {
    background-color: $primary;
    color: $white;
  }
}
</style>
