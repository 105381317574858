
<template>
  <footer
    id="footer"
    class="small mt-3"
  >
    <div class="d-flex align-items-center justify-content-center">
      <a
        href="https://www.facebook.com/111471354633361/"
        class="d-inline-block icon mb-3"
        target="_blank"
      >
        <Icon
          name="facebook"
          :class="$style.icon"
        /></a>
    </div>
    <hr class="border border-top-0 mb-2">
    <div class="container-md d-flex flex-column align-items-center justify-content-center py-4">
      <img
        class="img-fluid mb-4"
        src="@/assets/img/logo.png"
        alt="logo"
        style="max-width: 110px;"
      >
      <div class="text-primary mb-4 lh-lg">
        服務信箱：boistorche@gmail.com<br>
        服務電話：02-29290198<br>
        服務時間：10:00-18:00
      </div>
      <div class="text-grey small">
        Copyright © 2021
        <a
          class="text-underline"
          :href="`https://admin.omniec.com.tw/login?clientID=${clientID}`"
          target="_blank"
        >OmniEC</a> all rights reserved.&nbsp;Powered by <a
          href="https://www.reinbach.com.tw"
          target="_blank"
        >Reinbach</a> .
      </div>
    </div>
  </footer>
</template>
<script>
import '@/assets/icon/facebook.svg'
export default {
  setup () {
    const clientID = process.env.VUE_APP_clientID

    return { clientID }
  }
}
</script>
<style lang="scss" module>

  .icon {
    color: $primary;
    height: 1.5rem;
    width: 1.5rem;
  }

</style>
