<template>
  <!-- 聯絡我們 -->
  <teleport to="#modalWrap">
    <div
      v-show="showContactModal"
    >
      <div
        :class="$style[`shadow`]"
        @click="setShowContactModal(false)"
      />
      <div
        class="modal-lg position-fixed top-50 start-50 translate-middle"
        :class="$style[`modal`]"
      >
        <div class="modal-content pop-up rounded-0">
          <div class="d-flex justify-content-end">
            <Icon
              role="button"
              name="cross"
              class="m-3"
              :class="$style[`icon`]"
              aria-label="Close"
              @click="setShowContactModal(false)"
            />
          </div>
          <div
            class="modal-body text-center px-3 px-md-5 pb-5"
            :class="$style[`modal-body`]"
          >
            <h5 class="col-12 font-weight-bold mb-3">
              聯絡我們
            </h5>
            <p>謝謝您的主動聯絡，<br class="d-inline-block d-md-none">請留下要諮詢的問題，我們將盡快與您聯繫。</p>
            <div class="col-12 px-4">
              <div
                v-for="field in fieldList"
                :key="field"
              >
                <Textarea
                  v-if="field==='contents'"
                  :id="field"
                  v-model:input="state[field]"
                  class="mb-4"
                  :type="field"
                  :required="true"
                  :label="labelMap[field].label"
                  :placeholder="labelMap[field].placeholder"
                  :err-message="v$[field]?.$errors[0]?.$message || ''"
                  @focus="v$[field].$touch"
                />
                <Input
                  v-else
                  :id="field"
                  v-model:input="state[field]"
                  class="mb-4"
                  :type="field"
                  :required="true"
                  :autocomplete="'off'"
                  :label="labelMap[field].label"
                  :placeholder="labelMap[field].placeholder"
                  :err-message="v$[field]?.$errors[0]?.$message || ''"
                  @focus="v$[field].$touch"
                  @enter="createContact"
                />
              </div>
            </div>
            <div class="col-12 px-4">
              <button
                id="contactBtn"
                type="button"
                class="col-12 btn bg-primary text-white rounded-0"
                @click="createContact"
              >
                提交
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
import { computed, ref, inject } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { requiredHelper, emailHelper } from '@/hooks/useVuelidate.js'
import Input from '@/components/Input'
import Textarea from '@/components/Textarea'
import { useErrorMessage } from '@/hooks/errorMessage.js'

export default {
  components: {
    Input, Textarea
  },
  setup () {
    const { getters, dispatch } = useStore()
    const { getErrorMessage } = useErrorMessage()

    const memberID = computed(() => getters['member/getMemberID'])
    const setAlert = inject('setAlert')

    const showContactModal = ref(false)

    const fieldList = ref(['name', 'email', 'contents'])

    const state = ref({
      name: '',
      email: '',
      contents: ''
    })

    const labelMap = {
      name: {
        label: '姓名',
        placeholder: '請輸入你的姓名'
      },
      email: {
        label: '電子信箱',
        placeholder: '請輸入你的電子信箱'
      },
      contents: {
        label: '訊息',
        placeholder: '請輸入你的訊息'
      }
    }

    const rules = {
      name: { requiredHelper },
      email: { requiredHelper, emailHelper },
      contents: { requiredHelper }
    }

    const v$ = useVuelidate(rules, state)

    const clearState = () => {
      state.value = {
        name: '',
        email: '',
        contents: ''
      }
      v$.value.$reset()
    }

    const setShowContactModal = (show) => {
      let newValue
      if (typeof show !== 'object') {
        newValue = show
      } else {
        newValue = !showContactModal.value
      }
      // to open modal
      if (newValue) {
        clearState()
      }
      showContactModal.value = !showContactModal.value
    }

    // 送出聯絡表單
    const contactForm = ref(null)
    const createContact = async () => {
      v$.value.$touch()
      if (!v$.value.$error) {
        setShowContactModal(false)
        const payload = state.value

        if (memberID.value) {
          payload.memberID = memberID.value
        }

        try {
          const response = await dispatch('contact/createContact', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            setAlert(true, true, '傳送成功！我們將盡速與您聯絡。')
          }

          return response
        } catch (error) {
          console.log('send contact form error:' + ', ' + error)
          setAlert(true, false, `傳送失敗: ${error}`)
        }
      }
    }

    return {
      v$,
      labelMap,
      memberID,
      state,
      fieldList,
      contactForm,
      showContactModal,
      setShowContactModal,
      createContact
    }
  }
}
</script>
<style lang="scss" module>

.icon {
  fill: currentColor;
  height: 0.9rem;
  width: 0.9rem;
}

.shadow {
  background-color: rgba($dark, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: calc(#{$zindex-modal} - 1);
}

.modal {
  max-width: 600px;
  width: 80vw;
  z-index: $zindex-modal;
}

.modal-body{
  max-height: 80vh;
  overflow-y: auto;
}

</style>
