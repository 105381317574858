<template>
  <div
    :class="[$style.alert, {[$style.show]: alert.show}, {[$style.failed]: !alert.success}]"
    class="d-flex flex-row align-items-center justify-content-center text-dark h4"
  >
    <div
      :class="$style[`icon-wrap`]"
      class="me-3"
    >
      <Icon
        v-if="alert.success"
        :class="$style.icon"
        name="check"
      />
      <Icon
        v-if="!alert.success"
        :class="$style.icon"
        name="exclamation"
      />
    </div>
    {{ alert.text || '' }}
    <button
      type="button"
      role="button"
      class="btn h-100 p-0 me-3 position-absolute end-0"
      @click="closeAlert"
    >
      <Icon
        :class="$style.cross"
        name="cross"
      />
    </button>
  </div>
</template>
<script>
import { computed, inject } from 'vue'
import { useStore } from 'vuex'
export default {
  setup () {
    const { getters } = useStore()

    const setAlert = inject('setAlert')

    const alert = computed(() => getters.getAlert)
    const closeAlert = () => {
      setAlert(false)

      setTimeout(() => {
        setAlert(false)
      }, 100)
    }

    return { alert, closeAlert }
  }
}
</script>
<style lang="scss" module>

  .alert {
    background-color: #b7d4b8e6;
    left: 50%;
    width: 100vw;
    height: 80px;
    position: fixed;
    top: 0;
    transform: translate(-50%, -150%);
    transition: transform 0.5s;
    user-select: none;
    // header z-index 1020
    z-index: 1021;
  }

  .show {
    transform: translate(-50%, 0);
  }

  .cross {
    fill: $white;
    height: 1.5rem;
    margin-right: 0.5rem;
    width: 1.5rem;
  }

  .icon {
    fill: #b7d4b8e6;
    height: 1.2rem;
    margin: 0.3rem;
    width: 1.2rem;
  }

  .icon-wrap {
    background-color: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alert.failed{
    background-color: #ff7676;

    & .icon{
      fill: #ff7676;
    }
  }
</style>
