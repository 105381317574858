<template>
  <header
    id="header"
    class="bg-white"
    :class="$style[`header`]"
  >
    <nav class="navbar navbar-expand-md p-0 container-fluid flex-column">
      <div
        class="col-12 px-3 d-flex flex-row align-items-center justify-content-between justify-content-md-center my-md-2 position-relative"
        style="min-height: 60px;"
      >
        <!-- logo -->
        <router-link to="/">
          <img
            class="img-fluid d-none d-md-block"
            style="max-width: 110px;"
            src="@/assets/img/logo.png"
            alt="logo"
          >
          <img
            class="img-fluid d-block d-md-none"
            style="max-width: 140px;"
            src="@/assets/img/logo_mobile.png"
            alt="logo"
          >
        </router-link>
        <!-- tools -->
        <div
          id="nav-tools"
          class="position-absolute end-0 d-flex align-items-center nav-tools"
        >
          <div v-show="!showSearchForm">
            <div class="d-flex align-items-center">
              <router-link
                v-if="memberID"
                :to="'/member/detail'"
                role="button"
                class="d-none d-md-flex mx-2 mx-lg-3"
                :class="[$style[`tool`], $style[`tool-member`]]"
              >
                <Icon
                  name="member"
                  :class="$style[`icon`]"
                />
              </router-link>
              <router-link
                v-if="!memberID"
                id="nav-tool-login"
                to="/login"
                role="button"
                class="d-none d-md-flex mx-2 mx-lg-3"
                :class="[$style[`tool`], $style[`tool-member`]]"
              >
                <Icon
                  name="member"
                  :class="$style[`icon`]"
                />
              </router-link>
              <a
                id="cartCount"
                role="button"
                class="position-relative mx-2 mx-lg-3"
                :class="[$style[`tool`], $style[`tool-cart`]]"
                :data-amount="cartMount"
                @click="setShowCart"
              >
                <Icon
                  name="shopping-bag"
                  :class="$style[`icon`]"
                />
              </a>
            </div>
          </div>
          <div v-show="showSearchForm">
            <div
              class="position-relative w-100"
            >
              <input
                v-model="queryString"
                type="text"
                class="d-inline-block form-control rounded-pill w-100 my-4"
                :class="$style[`search-input`]"
                placeholder="找商品"
                @keydown.enter="push(queryString!=='' ? `/product/list?queryString=${queryString}` : '')"
              >
              <router-link
                :to="queryString!=='' ? `/product/list?queryString=${queryString}` : ''"
                class="d-flex border-0 align-items-center ms-1 me-lg-3 me-2 position-absolute text-dark"
                :class="$style[`search-icon`]"
              >
                <Icon
                  name="search"
                  :class="$style[`icon`]"
                />
              </router-link>
            </div>
          </div>
          <a
            role="button"
            class="d-none d-md-flex mx-2"
            :class="[$style[`tool`], $style[`tool-search`]]"
            @click="setShowSearchForm"
          >
            <div v-show="showSearchForm">
              <Icon
                name="cross"
                :class="$style[`icon`]"
              />
            </div>
            <div v-show="!showSearchForm">
              <Icon
                name="search"
                :class="$style[`icon`]"
              />
            </div>
          </a>

          <a
            v-if="memberID"
            role="button"
            class="d-none d-md-block text-primary me-3 text-decoration-none"
            @click="logoutMember"
          >登出</a>
          <button
            class="navbar-toggler border-0 navbar-light rounded-0 text-primary"
            style="height: 60px;"
            type="button"
            @click="setShowNav"
          >
            <Icon
              name="hamburger"
              :class="$style[`navbar-toggler-icon`]"
            />
          </button>
        </div>
      </div>

      <!-- cart -->
      <div class="w-100 bg-white">
        <div
          id="cart-card-shadow"
          :class="[{[$style[`active`]]: showCart}, $style[`cart-card-shadow`]]"
          @click="setShowCart()"
        />
        <div
          class="cart flex-column border-0 rounded-0"
          :class="[{[$style[`active`]]: showCart}, $style[`cart-card`]]"
        >
          <div
            class="card-body flex-column justify-content-start align-items-start overflow-auto container-fluid text-dark bg-white position-relative"
            :class=" $style[`cart-card-body`]"
          >
            <div
              v-for="(detail,index) in cart"
              :key="index"
              class="row align-items-center justify-content-between mb-3"
            >
              <div class="col-11 flex-grow-1 text-start d-flex justify-content-start">
                <img
                  :src="detail.mainImg ?? require('@/assets/img/nopic.gif')"
                  class="col-3"
                  @error="imageLoadError"
                >
                <router-link
                  :to="'/product/detail/' + detail.productID"
                  class="d-flex justify-content-center flex-column text-dark text-decoration-none flex-grow-1 ps-3"
                >
                  {{ detail.name }}
                  <br>{{ detail.spec1Name?detail.spec1Name + '：' : '' }}{{ detail.spec1Value?detail.spec1Value: '' }}{{ detail.spec2Name? '，' + detail.spec2Name + '：' : '' }}{{ detail.spec2Value?detail.spec2Value: '' }}
                </router-link>
                <!-- <div
                  v-else
                  class="text-center flex-grow-1 p-md-0 text-danger"
                >
                  {{ detail.qty }} x NTD {{ detail.fixedPrice }}
                </div> -->
              </div>
              <div class="col-1 text-center">
                <div
                  role="button"
                  @click="updateProNum(detail.cartID, 0, true, detail.storageQty)"
                >
                  <Icon
                    name="cross"
                    :class="$style[`icon`]"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="cart.length===0"
              class="d-flex flex-column w-75 position-absolute top-50 start-50 translate-middle align-items-center justify-content-center"
            >
              <div class="mb-2 text-center">
                購物車內還沒有商品喔
              </div>
              <router-link
                to="/product/list"
                class="btn btn-primary text-white rounded-0"
              >
                去看看其他商品
              </router-link>
            </div>
          </div>
          <div class="card-footer bg-white border-0 rounded-0 p-0">
            <router-link
              to="/cart"
              class="w-100 btn btn-primary rounded-0 text-white py-2"
            >
              查看購物車
            </router-link>
          </div>
        </div>
      </div>
      <Navbar />
    </nav>
  </header>
  <!-- 聯絡我們 -->
  <ContactModal ref="ContactModal" />
</template>
<script>
import { computed, onMounted, ref, inject, provide, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Navbar from '@/components/front/Navbar'
import ContactModal from '@/components/front/ContactModal'
import { useErrorMessage } from '@/hooks/errorMessage.js'

export default {
  components: {
    Navbar, ContactModal
  },
  setup () {
    const { getters, dispatch, commit } = useStore()
    const { push } = useRouter()
    const route = useRoute()
    const { getErrorMessage, getSuccessMessage } = useErrorMessage()

    const memberID = computed(() => getters['member/getMemberID'])
    const cartMount = computed(() => getters['cart/getCartMount'])
    const cart = computed(() => getters['cart/getCart'])
    const categoryList = computed(() => getters['product/getCategoryList'])

    const setAlert = inject('setAlert')

    const queryString = ref('')
    const showCart = ref(false)
    const showNav = ref(false)
    const showSearchForm = ref(false)
    const ContactModal = ref(null)

    const setShowCart = (show) => {
      if (typeof show !== 'object') {
        showCart.value = show
      } else {
        showCart.value = !showCart.value
      }
    }
    const setShowNav = (show) => {
      if (typeof show !== 'object') {
        showNav.value = show
      } else {
        showNav.value = !showNav.value
      }
    }

    watch(() => route.path, () => {
      setShowCart(false)
      setShowNav(false)
      setShowSearchForm(false)
    })

    const fieldList = ref(['name', 'email', 'contents'])

    const setShowSearchForm = (show) => {
      if (typeof show !== 'object') {
        showSearchForm.value = show
      } else {
        showSearchForm.value = !showSearchForm.value
      }
    }

    const setShowContactModal = (show) => {
      setShowNav(false)
      ContactModal.value.setShowContactModal(show)
    }

    provide('showNav', showNav)
    provide('setShowNav', setShowNav)
    provide('setShowContactModal', setShowContactModal)

    const getCategoryList = async () => {
      const payload = { }

      try {
        await dispatch('product/readCategoryList', { payload })
      } catch (error) {
        console.log(error)
      }
    }

    //   const timer = setInterval(function () {
    //     if (localStorage.getItem('memberID')) {
    //       const valueStr = localStorage.getItem('memberID')
    //       var valueObj = JSON.parse(valueStr)
    //       // console.log(new Date().getTime() - valueObj.time);
    //       if (new Date().getTime() - valueObj.time >= valueObj.expire) {
    //         localStorage.removeItem('memberID')
    //       }
    //     } else {
    //       clearInterval(timer)
    //     }
    //   }, 1000)
    // }

    const logoutMember = () => {
      commit('member/setMemberID', { data: null })
      commit('cart/setCart', { data: [] })
      commit('cart/setCartMount', { data: 0 })
      // localStorage.removeItem('memberID')
      setAlert(true, true, '您已成功登出')
      push('/')
    }

    const readCartMount = async () => {
      if (memberID.value) {
        const payload = { memberID: memberID.value }

        try {
          const response = await dispatch('cart/readCartMount', { payload })
          if (response.data.code !== 200) {
          // setAlert(true, false, response.data.errMsg)
          }
        } catch (error) {
          console.log('send contact form error:' + ', ' + error)
        // setAlert(true, false, error)
        }
      } else {
        commit('cart/setCartMount', { data: 0 })
      }
    }

    const imageLoadError = (e) => {
      e.target.src = require('@/assets/img/nopic.gif')
    }

    const readCart = async () => {
      if (memberID.value) {
        const payload = { memberID: memberID.value, offset: 0, pageSize: 10 }

        try {
          const response = await dispatch('cart/readCart', { payload })
          if (response.data.code !== 200) {
          // setAlert(true, false, response.data.errMsg)
          }
        } catch (error) {
          console.log('send contact form error:' + ', ' + error)
        // setAlert(true, false, error)
        }
      } else {
        commit('cart/setCartMount', { data: 0 })
      }
    }

    // 商品異動
    const updateProNum = async (cartID, num, isDelete, storageQty) => {
      setShowCart()
      if (!memberID.value) {
        setAlert(true, false, '請登入會員！')
        window.setTimeout(() => push('/login'), 2000)
        return null
      }
      const payload = { memberID, cartID }

      // 刪除
      if (isDelete) {
        try {
          const response = await dispatch('cart/deleteCartItem', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            setAlert(true, true, getSuccessMessage(response))
            await readCart()
            await readCartMount()
          }

          return response
        } catch (error) {
          // console.log('get user info error: ' + ', ' + error)
          setAlert(true, false, `刪除失敗: ${error}`)
        }
      }
    }

    if (categoryList.value.length === 0) {
      getCategoryList()
    }
    readCartMount()
    readCart()

    onMounted(() => {
      // var prevScrollpos = window.pageYOffset
      // window.addEventListener('scroll', () => {
      //   console.log(window.pageYOffset)
      // })
      // window.onscroll = function () {
      //   var currentScrollPos = window.pageYOffset
      //   if (prevScrollpos > currentScrollPos) {
      //     document.getElementByID('navbar-ul').style.top = '0'
      //   } else {
      //     document.getElementByID('navbar-ul').style.top = '-50px'
      //   }
      //   prevScrollpos = currentScrollPos
      // }
    })

    return {
      queryString,
      ContactModal,
      memberID,
      cart,
      fieldList,
      showCart,
      setShowCart,
      showNav,
      setShowNav,
      showSearchForm,
      setShowSearchForm,
      getCategoryList,
      setShowContactModal,
      cartMount,
      logoutMember,
      imageLoadError,
      updateProNum,
      categoryList,
      push
    }
  }
}
</script>
<style lang="scss" module>

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
}

.search-input, .search-input:focus {
  background-color: rgba($primary, 0.1);
  border: 0;
}

.search-icon {
  background-color: transparent;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.tool {
  align-items: center;
  background-color: $white;
  border-radius: 50%;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
}

.icon {
  fill: currentColor;
  height: 0.9rem;
  width: 0.9rem;
}

// search
.tool-search {
  color: $tertiary;
  transition: color 0.3s;
}

.tool-search:hover {
  color: $primary;
}

// member
.tool-member {
  border: solid 1px $tertiary;
  color: $tertiary;
  transition: border-color 0.3s, color 0.3s;
}

.tool-member:hover {
  border: solid 1px $primary;
  color: $primary;
}

// cart
.tool-cart {
  background-color: $tertiary;
  color: $white;
  transition: background-color 0.3s;
}

.tool-cart::after {
  background-color: $white;
  border: solid 1px $tertiary;
  border-radius: 50%;
  color: $dark;
  content: attr(data-amount);
  font-size: 0.3rem;
  height: 1rem;
  line-height: 1rem;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transform: translate(60%, -10%);
  transition: border-color 0.3s;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-cart:hover {
  background-color: $primary;
  color: $white;
}

.tool-cart:hover::after {
  border: solid 1px $primary;
}

// cart
.cart-card {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: none;
  max-height: 50%;
  max-width: 50%;
  position: absolute;
  right: 50px;
  top: 68px;
  z-index: 1;
}

.cart-card.active {
  display: flex;
}

.cart-card-body {
  flex-wrap: nowrap;
  min-height: 300px;
  min-width: 250px;
}

/* cart block for mobile */
@media screen and (max-width: 768px) {
  .cart-card {
    background-color: $white;
    display: flex;
    height: 100vh;
    justify-content: start;
    max-height: unset;
    max-width: unset;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform 0.3s;
    width: 80%;
    z-index: 1200;
  }

  .cart-card.active {
    transform: translateX(0%);
  }

  .cart-card-shadow {
    background-color: $dark;
    content: '';
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: -100vh;
    transition: opacity 0.3s;
    width: 100vw;
    z-index: 1199;
  }

  .cart-card-shadow.active {
    opacity: 0.5;
    top: 0;
  }
}

.navbar-toggler-icon {
  background-image: unset;
  fill: currentColor;
  height: 1.5rem;
  width: 1.5rem;
}

.shadow {
  background-color: rgba($dark, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: calc(#{$zindex-modal} - 1);
}

.modal {
  max-width: 600px;
  width: 80vw;
  z-index: $zindex-modal;
}

</style>
