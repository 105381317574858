<template>
  <div id="modalWrap" />
  <div class="d-flex flex-column min-vh-100">
    <FrontHeader />

    <router-view v-slot="{Component}">
      <template v-if="Component">
        <suspense>
          <component
            :is="Component"
            class="main flex-grow-1"
          />
        </suspense>
      </template>
    </router-view>

    <FrontFooter />
  </div>
  <Loading />
  <Alert />
</template>
<script>
import FrontHeader from '@/components/front/Header.vue'
import FrontFooter from '@/components/front/Footer.vue'
import Alert from '@/components/Alert.vue'
import Loading from '@/components/Loading.vue'
import { useStore } from 'vuex'
import { onMounted, provide } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
export default {
  components: {
    FrontHeader, FrontFooter, Alert, Loading
  },
  setup () {
    const { commit } = useStore()
    const { beforeEach } = useRouter()

    const scrollToTop = () => {
      if (document?.documentElement) {
        document.documentElement.scroll({ top: 0, behavior: 'smooth' })
      }
    }
    beforeEach((to, from) => {
      scrollToTop()
      // 返回 false 以取消导航
      return true
    })

    const setAlert = (show, success, text) => {
      commit('setAlert', {
        data: {
          show, success, text
        }
      })

      if (show) {
        setTimeout(() => {
          commit('setAlert', {
            data: {
              show: false
            }
          })
        }, 1200)
      }
    }
    provide('setAlert', setAlert)

    onMounted(() => {
      window.addEventListener('resize', () => {
        const innerWidth = window.windowInnerWidth
        commit('setIsMobile', innerWidth < 768)
      })
    })

    return {}
  }
}
</script>
<style lang="scss" module>

</style>
